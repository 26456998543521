import axios from 'axios'
import { router } from 'vue-router'
const request = axios.create({
  baseURL: 'https://backend.asynclab.club:8888/',
  timeout: 5000
})

//请求拦截器
request.interceptors.request.use(
  (config) => {
    //如果本地里有token 就将token值带到headers里就不用重复发token请求了
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
//响应拦截器，将服务器里的错误消息变成一个错误抛出
request.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response.data.message ===
      '无效的Authorization: 无效的Token'
    ) {
      localStorage.removeItem('token')
      router.push('/login')
    }

    return Promise.reject(error.response.data)
  }
)

//导出request实例
export default request
